"use strict";

var controller = null;

var animations = function () {
    var tooSmall = false;
    var maxWidth = 1200;

    if ($(window).width() < maxWidth) {
        tooSmall = true;
    }

    function initScrollMagic() {
        controller = new ScrollMagic.Controller();
        var fixed, column, duration;
        if ($('#fixed').length && $('.column-right').length) {
            fixed = $('#fixed').offset().top + $('#fixed').outerHeight(true);
            column = $('.column-right');
            duration = column.height() - fixed;
        }

        if ($(window).width() > 1200) {
            if ($('#fixed').length > 0) {
                new ScrollMagic.Scene({
                        triggerElement: '.column-fixed-wrapper',
                        offset: -25,
                        triggerHook: 0,
                        duration: duration
                    })
                    .setPin('.column-fixed-wrapper')
                    .addTo(controller);
            }
        }
    }

    if (!tooSmall) {
        initScrollMagic();
    }

    // zmiana szerokości okna przeglądarki może wielokrotnie odpalać skrypt
    // to rozwiązanie pozwala wstrzymać controller przed wielokrotnym wznawianiem lub zniszczeniem
    $(window).resize(function () {
        var wWidth = $(window).width();
        if (wWidth < maxWidth) {
            if (controller !== null && controller !== undefined) {
                // niszczy controller
                controller = controller.destroy(true);
            }
        } else if (wWidth >= maxWidth) {
            if (controller === null || controller === undefined) {
                // wznawia skrypt / controller jeśli nie został on wznowiony wcześniej
                initScrollMagic();
            }
        }
    });
};

var textOrphans = function () {
    $('p').each(function () {

        if ($(this).html().match(/class="order"/g)) return;

        var tekst = $(this).html();
        tekst = tekst.replace(/(\s)([\S])[\s]+/g, "$1$2&nbsp;"); //jednoznakowe
        tekst = tekst.replace(/(\s)([^<][\S]{1})[\s]+/g, "$1$2&nbsp;"); //dwuznakowe
        $(this).html(tekst);
    });
};

// var cookies = function () {
//     if ($.cookie('giodoCookies') !== '1') {
//         $('.cookies-alert').css('display', 'flex');
//     }
//     $('.cookies-alert button').on('click', function () {
//         $('.cookies-alert').slideToggle('slow', function () {
//             $.cookie('giodoCookies', '1', {
//                 expires: 365
//             });
//         });
//     });
// };

var fullheight = function () {
    var vw = $(window).width();
    var vh = $(window).height();
    var app = $('.app-index');

    if (vw >= 768) {
        app.css('height', vh + 'px');
    } else {
        app.css('height', '')
    }
}

var numberCorrection = function () {
    // var cardNumber = [];
    var cardNumber = ["n", "n", "n", "n", "n", "n", "n", "n", "n", "n", "n", "n", "n", "n", "n", "n"];

    $(".digits-wrapper input").each(function () {
        $(this).val('');
    });

    $(".digits-wrapper input").keyup(function (e) {
        if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
            cardNumber[$(this).data('digit')] = this.value;
            var stringCardNumber = cardNumber.filter(function (value, index, arr) {
                return value !== 'n';
            }).join('');
            $(this)
                .next()
                .focus();
            $('#card-number').val(stringCardNumber);
        }
    });

    $('.digits-wrapper input').keydown(function (e) {
        var that = $(this);

        //Checks if has value
        if (that.val().length > 0) {
            //Focus next if has value and Arrow Right Clicked
            if (e.keyCode === 39) {
                that.next().focus();
            }
            //Focus prev if has value and Arrow Left clicked
            if (e.keyCode === 37) {
                that.prev().focus();
            }
        }

        //Keyboard controls input
        //Backspace Key
        if (e.keyCode === 8) {
            that.each(function () {
                if ($(this).is(':focus')) {
                    if ($(this).val() !== '') {
                        $(this).val('');
                        return false;
                    } else {
                        $(this).prev().val('').focus();
                    }
                    var stringNewCardNumber = cardNumber.filter(function (value, index, arr) {
                        return value !== 'n';
                    }).join('');
                    $('#card-number').val(stringNewCardNumber);
                }
            });
        }
        //Delete Key
        if (e.keyCode === 46) {
            that.each(function () {
                if ($(this).is(':focus')) {
                    if ($(this).val() !== '') {
                        $(this).val('');
                        return false;
                    } else {
                        $(this).next().val('').focus();
                    }
                    var stringNewCardNumber = cardNumber.filter(function (value, index, arr) {
                        return value !== 'n';
                    }).join('');
                    $('#card-number').val(stringNewCardNumber);
                }
            });
        }

        //Arrow Right focus next digit input
        if (e.keyCode === 39) {
            that.next().focus();
        }
        //Arrow Left focus previous digit input
        if (e.keyCode === 37) {
            that.prev().focus();
        }
    });

    $("#phone").keypress(function (e) {
        if ($(this).val().length >= 9) {
            return false;
        }
        if ((e.which >= 48 && e.which <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
            return e.key;
        } else {
            return false;
        }
    });
    $("input[type='text']").keypress(function (e) {
        var firstChar = $(this).val();
        if (e.which === 32 && firstChar === "") {
            return false;
        }
    });
}

// mBank Validate
var validator = function () {

    $('.close').click(function (e) {
        $('.message-outer').fadeOut();
        $('#error-message').text('');
        e.preventDefault();
    });

    $('.close-message').click(function (e) {
        $('.alert-wrapper').fadeOut();
        e.preventDefault();
    });

    var pass = 0;

    $("#form").on('submit', function (e) {
        var form = $(this);
        var $error = $('#error-message');
        form.parsley().validate();
        if (pass === 0) {
            e.preventDefault();
            if (form.parsley().isValid()) {
                var cardNumber = $('#card-number').val();

                $('.message-outer').css('display', 'none');

                axios.get('/check-card-number?item=' + cardNumber)
                    .then(response => {
                        $('.message-outer').css('display', 'none');
                        var pass = 1;
                        form.unbind('submit').submit();
                    })
                    .catch(function (error) {
                        $('.message-outer').css('display', 'flex');
                        $('.alert-wrapper-success').remove();

                        switch (error.response.status) {
                            case 409:
                                $error.text('Termin aktywacji tej karty już minął');
                                break;
                            case 404:
                                $error.text('Błędny numer karty');
                                break;
                            default:
                                $error.text('Błędny numer karty');
                        }
                    });
            }
        }
    });

    $('#card-number').attr({
        'data-parsley-required': 'true',
        'data-parsley-minlength': '16'
    });

    $('#pharmacy-name').attr({
        'data-parsley-required': 'true',
        'data-parsley-minlength': '1',
        'data-parsley-pattern': '([À-žA-Za-z-( )]+)',
        'pattern': '([À-žA-Za-z-( )]+)'
    });

    $('#firstname').attr({
        'data-parsley-required': 'true',
        'data-parsley-minlength': '1',
        'data-parsley-maxlength': '20',
        'data-parsley-pattern': '([À-žA-Za-z-( )]+)',
        'pattern': '([À-žA-Za-z-( )]+)'
    });

    $('#lastname').attr({
        'data-parsley-required': 'true',
        'data-parsley-minlength': '1',
        'data-parsley-maxlength': '20',
        'data-parsley-pattern': '([À-žA-Za-z-( )]+)',
        'pattern': '([À-žA-Za-z-( )]+)'
    });

    $('#check1').attr({
        'data-parsley-required': 'true',
    });

    $('#check2').attr({
        'data-parsley-required': 'true',
    });

    $('#check3').attr({
        'data-parsley-required': 'false',
    });

    $('#check4').attr({
        'data-parsley-required': 'false',
    });

    $('#check5').attr({
        'data-parsley-required': 'false',
    });

    $('#phone').attr({
        'data-parsley-maxlength': 9,
        'data-parsley-minlength': 9,
        'data-parsley-pattern': '[0-9]{9}',
        'data-parsley-required': 'true',
    });

    $('#email').attr({
        'data-parsley-type': 'email',
    });
}

var verifyMillenniumCard = function () {
    var pass = 0;
    var openModal = $('.card-restore-inner');
    var modal = $('#m-details-form');
    var closeModal = $('#close-card-details');

    var dialPrefix = $('#fetch-phone-number-prefix').val();
    var phone = $('#fetch-phone-number');
    var formFooter = $('.card-form-footer');
    var data = [];
    var busy = false;

    data.prefixPhone = dialPrefix;
    $('.form-field').removeClass('form-field-disabled');

    // Open popup
    openModal.on('click', function(e) {
        e.preventDefault();

        modal.fadeIn(500);
    });

    // Close popup & clear form input
    closeModal.on('click', function(e) {
        e.preventDefault();       

        modal.fadeOut(500);
        reset(1);
    });

    var validateDetails = (callback) => {
        var element = callback.$element[0];
        var success = 'rgb(46, 204, 113)';
        var errors = 'rgb(231, 76, 60)';

        return callback.validationResult === true ? element.style.borderColor = success : element.style.borderColor = errors;
    }

    var loading = (status) => {
        status ? $('#fetch-card-details').addClass('form-disabled') : $('#fetch-card-details').removeClass('form-disabled');
    }

    var addSuccessMessage = (response) => {
        var createElement = content => { return content };
        var parent = $('.form-group-items-card-number');
        var childElement = $('.form-loaded');

        childElement.remove();

        parent.prepend(
            createElement(`<div class="form-loaded">
                <div class="form-loaded-text">
                    <h2>${response}</h2>
                </div>
            </div>`)
        );
    }

    var removeErrorMessage = () => {
        var element = $('#card-details-error-message');
        element.remove();
    }

    var throwErrorResponse = (response) => {
        var createElement = content => { return content; };

        removeErrorMessage();

        formFooter.prepend(
            createElement(`<div id="card-details-error-message" class="card-details-message">  
                <div class="message-inner">
                    <div class="message-text">
                        <p id="result-message">${response}</p>
                    </div>
                    <div class="message-icon">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="feather feather-alert-circle">
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="12"></line>
                                <line x1="12" y1="16" x2="12.01" y2="16"></line>
                            </svg>
                        </span>
                    </div>
                </div>                        
            </div`)
        );    
    }

    var reset = (term) => {
        var card = $('#fetch-card-number');
        var dateOfBirth = $('#fetch-birthday');
        var prefix = $('#fetch-phone-number-prefix');
        var phone = $('#fetch-phone-number');
        
        var tags = [card, dateOfBirth, prefix, phone];

        var resetStyles = () => {            
            for(var i = 0; i < tags.length; i++) {
                tags[i].removeAttr('style').removeClass('parsley-success');
            }           
        }

        var resetForm = () => {            
            for (let i = 0; i < tags.length; i++) {
                if(tags[i].hasClass('prefix-select')) {
                    tags[i].val('48').removeAttr('style').removeClass('parsley-success');
                } else {
                    tags[i].val('').removeAttr('style').removeClass('parsley-success');
                }
            }
        }

        switch(term) {
            case 0:
                resetStyles();
                break;
            case 1: 
                resetForm();
                break;
            default:
                return;
        }
    }

    var format = d => { 
        var d = new Date(d),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }    

    var cardNumberConverter = d => {
        var field = $('.digits-wrapper input');
        var fieldArray = d.split('', d.length);

        field.each(function(i, el) {
            el.value = fieldArray[i];
        });
    }

    var fieldError = (field, message) => {
        var blockCreate = content => { return content; };

        // var item = field.siblings('.form-field');

        field.append(
            blockCreate(`<div class="personal-details-error">
                <div class="error-message">
                    <p>${message}</p>
                </div>
            </div>`)
        );
    }

    var loadForm = (edit, list, isEditable) => {
        var canEditFields = edit;
        var list = list;
        var listArray = {
            cardNumber: $('#card-number'),
            citizenship: $('#citizenship'),
            firstname: $('#firstname'),
            secondname: $('#secondname'),
            lastname: $('#lastname'),
            pesel: $('#pesel'),
            birthday: $('#birthday'),
            birthPlace: $('#birth-place'),
            typeDocument: $('#type-document'),
            numberDocument: $('#number-document'),
            country: $('#country-document'),
            expiryDateDocument: $('#expiry-date-document'),
            prefixPhone: $('#phone-number-prefix'),
            phone: $('#phone-number'),
            email: $('#client-email'),
            motherFirstname: $('#mothers-name'),
            motherMaidenName: $('#mothers-maiden-name'),
            fatherFirstname: $('#fathers-name'),
            regulationFirst: $('#check1'),
            regulationSecond: $('#check2'),
            regulationThird: $('#check3'),
            regulationFourth: $('#check4'),
            indirectConsent: $('#check5'),

        }             
                
        cardNumberConverter(list.cardNumber);
        listArray.cardNumber.val(list.cardNumber);
        // Insert Data into form
        listArray.citizenship.val(list.citizenship);
        listArray.firstname.val(list.firstname);
        listArray.secondname.val(list.secondname);
        listArray.lastname.val(list.lastname);
        listArray.pesel.val(list.pesel);
        listArray.birthday.val(format(list.birthday.date));
        listArray.birthPlace.val(list.birthPlace);
        listArray.typeDocument.val(list.typeDocument);
        listArray.numberDocument.val(list.numberDocument);
        listArray.country.val(list.country);
        listArray.expiryDateDocument.val(format(list.expiryDateDocument.date));
        listArray.prefixPhone.val(list.prefixPhone);
        listArray.phone.val(list.phone);
        listArray.email.val(list.email);
        listArray.motherFirstname.val(list.motherFirstname);
        listArray.motherMaidenName.val(list.motherMaidenName);
        listArray.fatherFirstname.val(list.fatherFirstname);

        listArray.regulationFirst.prop('checked', list.regulationFirst);
        listArray.regulationSecond.prop('checked', list.regulationSecond);
        listArray.regulationThird.prop('checked', list.regulationThird);
        listArray.regulationFourth.prop('checked', list.regulationFourth);
        listArray.indirectConsent.prop('checked', list.indirectConsent);

        if(list.typeDocument !== 1) {
            listArray.numberDocument.attr({
                'maxlength': 50,
                'data-parsley-pattern': null,
            })
        } else {
            listArray.numberDocument.attr({
                'maxlength': 9,
                'data-parsley-pattern': '([A-Z]{3})+([0-9]{6})',
            })
        }        

        $('.digits-wrapper input').addClass('form-field-disabled');
        
        if(isEditable === true) {
            // FORM HAS EDITABLE FIELDS
            $('#validate').removeAttr('disabled');

            for(var k in listArray) {
                var fields = $('.form-field[name="client_milenium['+k+']"]');
                
                fields.addClass('form-field-disabled').attr('disabled', 'disabled');
            }

            for(var i = 0; i < canEditFields.length; i++) {            
                var field = $('.form-field[name="client_milenium['+canEditFields[i]+']"]')

                field.removeClass('form-field-disabled').removeAttr('disabled');

                if(canEditFields[i] === 'citizenship') 
                    fieldError($('.group-item[data-id="'+canEditFields[i]+'"]'), 'Podaj właściwy kraj pochodzenia');

                if(canEditFields[i] === 'firstname') 
                    fieldError($('.group-item[data-id="'+canEditFields[i]+'"]'), 'Podaj właściwe imię');

                if(canEditFields[i] === 'secondname') 
                    fieldError($('.group-item[data-id="'+canEditFields[i]+'"]'), 'Podaj właściwe drugie imię');
    
                if(canEditFields[i] === 'lastname') 
                    fieldError($('.group-item[data-id="'+canEditFields[i]+'"]'), 'Podaj właściwe nazwisko');

                if(canEditFields[i] === 'pesel')
                    fieldError($('.group-item[data-id="'+canEditFields[i]+'"]'), 'Podaj poprawny numer pesel');

                if(canEditFields[i] === 'birthday')
                    fieldError($('.group-item[data-id="'+canEditFields[i]+'"]'), 'Podaj poprawną datę urodzenia');

                if(canEditFields[i] === 'birthPlace')
                    fieldError($('.group-item[data-id="'+canEditFields[i]+'"]'), 'Podaj właściwe miejsce urodzenia');

                if(canEditFields[i] === 'typeDocument') 
                    fieldError($('.group-item[data-id="'+canEditFields[i]+'"]'), 'Podaj właściwy typ dokumentu');                    
    
                if(canEditFields[i] === 'numberDocument')
                    fieldError($('.group-item[data-id="'+canEditFields[i]+'"]'), 'Podaj poprawny numer dokumentu');
    
                if(canEditFields[i] === 'country')
                    fieldError($('.group-item[data-id="'+canEditFields[i]+'"]'), 'Podaj właściwy kraj wydania dokumentu');
    
                if(canEditFields[i] === 'expiryDateDocument')
                    fieldError($('.group-item[data-id="'+canEditFields[i]+'"]'), 'Podaj poprawną datę ważności dokumentu');
    
                if(canEditFields[i] === 'prefixPhone')
                    fieldError($('.group-item-visible[data-id-prefix="'+canEditFields[i]+'"]'), 'Podaj poprawny numer kierunkowy');

                if(canEditFields[i] === 'phone')
                    fieldError($('.group-item-visible[data-id-phone="'+canEditFields[i]+'"]'), 'Podaj poprawny numer telefonu');
    
                if(canEditFields[i] === 'motherFirstname')
                    fieldError($('.group-item[data-id="'+canEditFields[i]+'"]'), 'Podaj właściwe imię matki');
    
                if(canEditFields[i] === 'motherMaidenName')
                    fieldError($('.group-item[data-id="'+canEditFields[i]+'"]'), 'Podaj właściwe nazwisko panieńskie matki');
    
                if(canEditFields[i] === 'fatherFirstname')
                    fieldError($('.group-item[data-id="'+canEditFields[i]+'"]'), 'Podaj właściwe imię ojca');
            }

        } else {
            // FORM NOT EDITABLE
            $('#validate').attr('disabled', 'disabled'); 

            for(var k in listArray) {
                var fields = $('.form-field[name="client_milenium['+k+']"]');
                fields.addClass('form-field-disabled').attr('disabled', 'disabled');
            }

            if($('#check5').hasClass('form-field-disabled')) {
                $('.group-rollout-checkbox-wrapper').addClass('show');
            }
        }            
    }
    
    $('#fetch-card-details').on('submit', function (e) {
        var form = $(this);        

        busy = true;
        
        form.parsley().validate();       
        loading(busy); 
        
        if (pass === 0) {                               
            e.preventDefault();
            
            if (form.parsley().isValid()) {
                
                axios.post('/karta-millenium/weryfikacja', { 
                    cardNumber: data.cardNumber,
                    birthday: data.birthday,
                    prefixPhone: data.prefixPhone,                    
                    phone: data.phone
                }).then(response => {
                    $('.personal-details-error').remove();
                    
                    if(response.status === 200) {                        
                        var editable = response.data.fieldToEdit;
                        var list = response.data.list;  
                                        
                        if (response.data.canEditField === true) {
                            loadForm(editable, list, true);
                            addSuccessMessage('Edycja danych');
                        } else {
                            loadForm(editable, list, false);
                            addSuccessMessage('Zarejestrowane dane');
                        }

                        reset(1);

                        sessionStorage.setItem('editable', response.data.canEditField);
                        modal.fadeOut(300);
    
                        loading(busy);
                        removeErrorMessage();

                        $('#fetch-card-details').removeClass('form-disabled');

                        if($('.message-outer.message-outer-error').length > 0) {
                            $('.message-outer.message-outer-error').css('display','none');
                            $('#error-message').text('');
                        }
                    }
                }).catch(error => {
                    busy = false;
                    
                    if(error.response.status === 409) {
                        $('#fetch-card-details').removeClass('form-disabled');
                        throwErrorResponse(error.response.data);
                    }

                    loading(busy);
                    reset(0);
                });        
            } else {
                busy = false;

                loading(busy);
                reset(0);
            }
        }            
    }); 
     
    $('#fetch-card-number').attr({
        'data-parsley-required': 'true',
        'data-parsley-minlength': '19',
        'data-parsley-maxlength': '19',
        'minlength': '19',
        'maxlength': '19',
        'data-parsley-error-message': 'Błędny numer karty'
    }).parsley().on('field:validated', function (callback) {
        return validateDetails(callback);
    });
    
    $('#fetch-birthday').attr({
        'data-parsley-type': 'date',
        'data-parsley-required': 'true',
        'data-parsley-pattern': '[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])',
        'data-parsley-error-message': 'Błędna data urodzenia'
    }).parsley().on('field:validated', function (callback) {
        return validateDetails(callback);
    });

    $('#fetch-phone-number-prefix').attr({
        'data-parsley-required': 'true'
    }).parsley().on('field:validated', function (callback) {
        return validateDetails(callback);
    });

    $('#fetch-phone-number').attr({
        'data-parsley-required': 'true',
        'data-parsley-type': 'digits',
        'data-parsley-minlength': '9',
        'data-parsley-maxlength': '9',
        'minlength': '9',
        'maxlength': '9',
        'data-parsley-error-message': 'Błędny numer telefonu'
    }).parsley().on('field:validated', function (callback) {
        return validateDetails(callback);
    });

    $('#fetch-card-number').on('keypress keyup blur', function(e) {       
        var $value = $(this).val() ;
        var n = $value.split('-').join('');            
        var ASCIICode = (e.which) ? e.which : e.keyCode;

        if(ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57) && (e.keyCode <= 96 && e.keyCode >= 105)) {
            e.preventDefault();
        } else {
            if(n.length > 0) {
                n = n.match(new RegExp('[0-9]{1,4}', 'g')).join('-');
            }                
        }
        
        data.cardNumber = $(this).val().replace(/-/g, '');

        if(n.length >= 19) e.preventDefault();

        return $(this).val(n);
    });

    $('#fetch-birthday').on('change', function() {
        var $value = $(this).val();
        data.birthday = $value;
    })

    $('#fetch-phone-number-prefix').on('change', function(e) {
        var that = $(this);
        var $value = that.val();
        var min_chars, max_chars;

        phone.val('');

        $value === '48' ? [ min_chars = 9, max_chars = 9 ] : [ min_chars = 5, max_chars = 15 ];

        phone.attr({ 'data-parsley-minlength': min_chars, 'data-parsley-maxlength': max_chars, 'minlength': min_chars, 'maxlength': max_chars })

        data.prefixPhone = $value;
        return $value;
    });

    $('#fetch-phone-number').on('keypress keyup blur', function(e) { 
        var $value = $(this).val(); 
        var n = $value.split(' ').join('');            
        var ASCIICode = (e.which) ? e.which : e.keyCode;              

        if(ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57) && (e.keyCode <= 96 && e.keyCode >= 105)) {
            e.preventDefault();
        } else {
            if(n.length > 0) {
                n = n.match(new RegExp('[0-9]', 'g')).join('');
            }                
        }

        data.phone = $(this).val();

        return $(this).val(n);
    });
}

// Millennium 
var validateMillenniumCard = function () {
    $('.close').click(function (e) {
        $('.message-outer').fadeOut();
        $('#error-message').text('');
        e.preventDefault();
    });

    $('.close-message').click(function (e) {
        $('.alert-wrapper').fadeOut();
        e.preventDefault();
    });

    var pass = 0;
    
    if ($('#form_millenium').length !== 0) {       
        $("#form_millenium").on('submit', function (e) {
            var form = $(this);
            var $error = $('.message-outer p, .message-outer-error h2');
            form.parsley().validate();

            if (pass === 0) {
                e.preventDefault();
                if (form.parsley().isValid()) {                   
                    if("editable" in sessionStorage) {
                        var client_milenium_history = {};
                        client_milenium_history['cardNumber'] = $('#card-number').val();
                        client_milenium_history['citizenship'] = $('#citizenship').val();
                        client_milenium_history['firstname'] = $('#firstname').val();
                        client_milenium_history['secondname'] = $('#secondname').val();
                        client_milenium_history['lastname'] = $('#lastname').val();
                        client_milenium_history['pesel'] = $('#pesel').val();
                        client_milenium_history['birthday'] = $('#birthday').val();
                        client_milenium_history['birthPlace'] = $('#birth-place').val();
                        client_milenium_history['typeDocument'] = $('#type-document').val();
                        client_milenium_history['numberDocument'] = $('#number-document').val();
                        client_milenium_history['country'] = $('#country-document').val();
                        client_milenium_history['expiryDateDocument'] = $('#expiry-date-document').val();
                        client_milenium_history['prefixPhone'] = $('#phone-number-prefix').val();
                        client_milenium_history['phone'] = $('#phone-number').val();
                        client_milenium_history['email'] = $('#client-email').val();
                        client_milenium_history['motherFirstname'] = $('#mothers-name').val();
                        client_milenium_history['motherMaidenName'] = $('#mothers-maiden-name').val();
                        client_milenium_history['fatherFirstname'] = $('#fathers-name').val();
                        client_milenium_history['regulationFirst'] = $('#check1').prop('checked');
                        client_milenium_history['regulationSecond'] = $('#check2').prop('checked');
                        client_milenium_history['regulationThird'] = $('#check3').prop('checked');
                        client_milenium_history['regulationFourth'] = $('#check4').prop('checked');
                        client_milenium_history['indirectConsent'] = $('#check5').prop('checked');
                        client_milenium_history['save'] = 1;

                        axios.post('/karta-millenium/edycja', { client_milenium_history }).then(response => {
                            var pass = 1;
                            if(response.status === 200) {
                                sessionStorage.setItem('savedForm', true);
                                form.unbind('submit').submit();
                            }                            

                        }).catch(error => {

                        });

                    } else {
                        const cardNumber = $('#card-number').val();

                        axios.get(`/check-card-number-milenium?item=${cardNumber}`)
                        .then(response => {
                            var pass = 1;
                            
                            $('.message-outer').css('display', 'none');

                            if(response.status === 200) {
                                sessionStorage.setItem('registerSuccess', true);
                                
                                $('html, body').animate({ scrollTop: $('form').offset().top }, 500);

                                if($('.alert-wrapper-success-fixed').length > 0) {
                                    $('body').css('overflow', 'hidden');
                                }
                                form.unbind('submit').submit();
                            }                            
                        })
                        .catch(error => {
                            $('.message-outer').css('display', 'flex');
                            $('.alert-wrapper-success').remove();

                            $('html, body').animate({ scrollTop: $('form').offset().top }, 500);

                            switch (error.response.status) {
                                case 409:
                                    $error.text('Ten numer karty nie istnieje lub jest już zajęty.');
                                    break;
                                case 404:
                                    $error.text('Karta Millenium o tym numerze nie istnieje.');
                                    break;
                                default:
                                    $error.text('');
                            }
                        });    
                    }                
                }
            }
        });

        var styleSuccess = 'rgb(0, 255, 188)';
        var validateComplete = function (callback, style) {
            var element = callback.$element[0];

            if (callback.validationResult === true) {
                element.style.borderColor = style;
            } else {
                element.style.borderColor = '';
            }

            return;
        }

        $('#card-number').attr({
            'data-parsley-required': 'true',
            'data-parsley-maxlength': '16',
            'data-parsley-minlength': '16'
        }).parsley().on('field:validated', function (callback) {
            return validateComplete(callback, styleSuccess);
        });

        $('#citizenship').attr({
            'data-parsley-required': 'true'
        }).parsley().on('field:validated', function (callback) {
            return validateComplete(callback, styleSuccess);
        });

        $('#firstname').attr({
            'data-parsley-required': 'true',
            'data-parsley-minlength': '1',
            'data-parsley-maxlength': '20',
            'data-parsley-pattern': '([À-žA-Za-z-( )]+)',
            'pattern': '([À-žA-Za-z-( )]+)'
        }).parsley().on('field:validated', function (callback) {
            return validateComplete(callback, styleSuccess);
        });

        $('#secondname').attr({
            'data-parsley-minlength': '1',
            'data-parsley-maxlength': '20',
            'data-parsley-pattern': '([À-žA-Za-z-( )]+)',
            'pattern': '([À-žA-Za-z-( )]+)'
        }).parsley().on('field:validated', function (callback) {
            return validateComplete(callback, styleSuccess);
        });

        $('#lastname').attr({
            'data-parsley-required': 'true',
            'data-parsley-minlength': '1',
            'data-parsley-maxlength': '30',
            'data-parsley-pattern': '([À-žA-Za-z-( )]+)',
            'pattern': '([À-žA-Za-z-( )]+)'
        }).parsley().on('field:validated', function (callback) {
            return validateComplete(callback, styleSuccess);
        });

        $('#pesel').attr({
            'data-parsley-type': 'digits',
            'data-parsley-minlength': '11',
            'data-parsley-maxlength': '11',
            'data-parsley-required': 'true'
        }).parsley().on('field:validated', function (callback) {
            return validateComplete(callback, styleSuccess);
        });

        $('#birthday').attr({
            'data-parsley-type': 'date',
            'data-parsley-required': 'true',
            'data-parsley-pattern': '[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])'
        }).parsley().on('field:validated', function (callback) {
            return validateComplete(callback, styleSuccess);
        });

        $('#birth-place').attr({
            'data-parsley-required': 'true',
            'data-parsley-minlength': '1',
            'data-parsley-pattern': '([À-žA-Za-z-( )]+)',
            'pattern': '([À-žA-Za-z-( )]+)'
        }).parsley().on('field:validated', function (callback) {
            return validateComplete(callback, styleSuccess);
        });

        $('#type-document').attr({
            'data-parsley-required': 'true'
        }).parsley().on('field:validated', function (callback) {
            return validateComplete(callback, styleSuccess);
        });

        $('#number-document').attr({
            'data-parsley-required': 'true',
            'data-parsley-minlength': '1',
        }).parsley().on('field:validated', function (callback) {
            return validateComplete(callback, styleSuccess);
        });

        $('#country-document').attr({
            'data-parsley-required': 'true'
        }).parsley().on('field:validated', function (callback) {
            return validateComplete(callback, styleSuccess);
        });

        $('#expiry-date-document').attr({
            'data-parsley-type': 'date',
            'data-parsley-required': 'true',
            'data-parsley-pattern': '[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])'
        }).parsley().on('field:validated', function (callback) {
            return validateComplete(callback, styleSuccess);
        });

        $('#phone-number-prefix').attr({
            'data-parsley-required': 'true'
        }).parsley().on('field:validated', function (callback) {
            return validateComplete(callback, styleSuccess);
        });

        $('#phone-number').attr({
            'data-parsley-required': 'true',
            'data-parsley-type': 'digits',
        }).parsley().on('field:validated', function (callback) {
            return validateComplete(callback, styleSuccess);
        });

        $('#client-email').attr({
            'data-parsley-minlength': '1',
            'data-parsley-type': 'email'
        }).parsley().on('field:validated', function (callback) {
            return validateComplete(callback, styleSuccess);
        });

        $('#mothers-name').attr({
            'data-parsley-required': 'true',
            'data-parsley-minlength': '1',
            'data-parsley-maxlength': '50',
            'data-parsley-pattern': '([À-žA-Za-z-( )]+)',
            'pattern': '([À-žA-Za-z-( )]+)'
        }).parsley().on('field:validated', function (callback) {
            return validateComplete(callback, styleSuccess);
        });

        $('#mothers-maiden-name').attr({
            'data-parsley-required': 'true',
            'data-parsley-minlength': '1',
            'data-parsley-maxlength': '50',
            'data-parsley-pattern': '([À-žA-Za-z-( )]+)',
            'pattern': '([À-žA-Za-z-( )]+)'
        }).parsley().on('field:validated', function (callback) {
            return validateComplete(callback, styleSuccess);
        });

        $('#fathers-name').attr({
            'data-parsley-required': 'true',
            'data-parsley-minlength': '1',
            'data-parsley-maxlength': '50',
            'data-parsley-pattern': '([À-žA-Za-z-( )]+)',
            'pattern': '([À-žA-Za-z-( )]+)'
        }).parsley().on('field:validated', function (callback) {
            return validateComplete(callback, styleSuccess);
        });

        $('#check1').attr({
            'data-parsley-required': 'true',
        });

        $('#check2').attr({
            'data-parsley-required': 'true',
        });
    }
}

var validateDocumentType = function () {
    var checkDocumentType = $('#type-document');
    var checkDocumentNumber = $('#number-document');

    // Check for input id type-document
    if (checkDocumentType.length > 0) {
        let selectedType = 1;
        // Match Regex Function
        var validateDocumentNumber = function (n, regex, event, maxlength) {
            if (n.match(regex)) {
                if (n.length === maxlength) {
                    event.preventDefault();
                }
            }
            return n;
        }

        // Check for selected documentType at window load
        if (selectedType === 1) {
            // Set Maxlength attr
            checkDocumentNumber.attr({
                'maxlength': 9,
                'data-parsley-pattern': '([A-Z]{3})+([0-9]{6})',
            });
            // Settings when user is typing
            checkDocumentNumber.on('keypress', function (evt) {
                let inputValue = null;
                let regex = /^([A-Z]{3})+([0-9]{6})$/i;
                inputValue = evt.target.value;
                validateDocumentNumber(inputValue, regex, evt);
            });
        } else {
            // Set Maxlength attr
            checkDocumentNumber.attr({
                'maxlength': 50,
                'data-parsley-pattern': null
            });
        }

        // check for selected value when state is changed
        checkDocumentType.on('change', function () {
            selectedType = parseInt(checkDocumentType.val());
            if (selectedType === 1) {
                // Set Maxlength attr
                checkDocumentNumber.attr({
                    'maxlength': 9,
                    'data-parsley-pattern': '([A-Z]{3})+([0-9]{6})',
                });
                // Settings when user is typing
                checkDocumentNumber.on('keypress', function (evt) {
                    let inputValue = null;
                    let regex = /^([A-Z]{3})+([0-9]{6})$/i;
                    inputValue = evt.target.value;
                    validateDocumentNumber(inputValue, regex, evt);
                });
            } else {
                // Set Maxlength attr
                checkDocumentNumber.attr({
                    'maxlength': 50,
                    'data-parsley-pattern': null
                });
            }
        });
    }
}

var validatePhoneNumberLength = function () {
    var checkPhoneNumberPrefix = $('#phone-number-prefix');
    var checkPhoneNumber = $('#phone-number');

    if (checkPhoneNumberPrefix.length > 0) {
        let dialPrefixSelected = 48;

        if (dialPrefixSelected === 48) {
            checkPhoneNumber.attr({
                minlength: 9,
                maxlength: 9,
                pattern: '[0-9]{9}',
                'data-parsley-pattern': '[0-9]{9}'
            });
        } else {
            checkPhoneNumber.attr({
                minlength: 1,
                maxlength: 15,
                pattern: '[0-9]+',
                'data-parsley-pattern': '[0-9]+'
            });
        }

        checkPhoneNumberPrefix.on('change', function (e) {
            let dialPrefixSelected = e.target.value;

            if (dialPrefixSelected === '48') {
                checkPhoneNumber.attr({
                    minlength: 9,
                    maxlength: 9,
                    pattern: '[0-9]{9}',
                    'data-parsley-pattern': '[0-9]{9}'
                });
            } else {
                checkPhoneNumber.attr({
                    minlength: 1,
                    maxlength: 15,
                    pattern: '[0-9]+',
                    'data-parsley-pattern': '[0-9]+'
                });
            }
        });
    }
}

var clearForm = () => {
    $('#card-number').val('');
    $('#citizenship').val('PL');
    $('#firstname').val('');
    $('#secondname').val('');
    $('#lastname').val('');
    $('#pesel').val('');
    $('#birthday').val('');
    $('#birth-place').val('');
    $('#type-document').val(1);
    $('#number-document').val('');
    $('#country-document').val('PL');
    $('#expiry-date-document').val('');
    $('#phone-number-prefix').val('48');
    $('#phone-number').val('');
    $('#client-email').val('');
    $('#mothers-name').val('');
    $('#mothers-maiden-name').val('');
    $('#fathers-name').val('');

    $('#check1').prop('checked', false);
    $('#check2').prop('checked', false);
    $('#check3').prop('checked', false);
    $('#check4').prop('checked', false);
    $('#check5').prop('checked', false);
}

var formSavedSuccess = (response) => {
    var createElement = content => { return content };
    var parent = $('.form-group-items-card-number');
    var childElement = $('.form-loaded .form-loaded-success');

    childElement.remove();

    parent.prepend(
        createElement(`<div class="form-loaded form-loaded-success">
            <div class="form-loaded-text">
                <h2>${response}</h2>
            </div>
        </div>`)
    );
}


$(document).on('click', '#form .styled-checkbox', function () {
    var $t = $(this);
    if ($t.is(':checked')) {
        $t.attr('checked', true);
    } else {
        $t.removeAttr('checked');
    }
});

$('.group-checkbox-wrapper input').on('click', function(){
    var input = $(this);
    if (input.is(':checked')) {
        $('.group-rollout-checkbox-wrapper').addClass('show');
    } else {
        var checkboxWrapper = $('.group-rollout-checkbox-wrapper');
        checkboxWrapper.removeClass('show');
        checkboxWrapper.find('#check3').prop('checked', false);
        checkboxWrapper.find('#check4').prop('checked', false);
    }
});

$('.addon-agree-button').on('click', function(){
    var text =  $(this).next();

    if(text.hasClass('show')){
        $(this).html('Rozwiń');
        text.removeClass('show');
    } else {
        $(this).html('Zwiń');
        text.addClass('show');
    }
});

$(document).on('click', '.show-more-button', function (e) {
    e.preventDefault();
    $(this).siblings().toggleClass('more-text-active');
    if ($(this).siblings().hasClass('more-text-active')) {
        $(this).text('Pokaż mniej')
    } else {
        $(this).text('Pokaż więcej')
    }

    controller = controller.destroy(true);
    animations();
});

$(window).on('resize', function () {
    fullheight();
})

$(window).on('load', function () {
    function fadeElement(el, val) {
        return el.css('opacity', val);
    }
    setTimeout(() => {
        fadeElement($('#app'), 1);
    }, 650);
});

var faq = function() {
    var id = $('#faq-list ul');
    var items = content => { return content; };

    if(id.length > 0) {
        axios.get('/faqs')
            .then(response => {            
                var list = response.data;            

                for(var i = 0; i < list.length; i++) {
                    id.append(
                        items(`<li class="question-item">
                            <div class="question"><p>${list[i].title}</p></div>
                            <div class="answer">${list[i].description}</div>
                        </li>`)
                    )
                }           
                
                questions();
            })
            .catch(error => {
                // console.log(error.response);
            })
    }
}

var questions = function () {
    $('.question-item').find('.answer').slideUp();

    $('.question-item').on('click', function () {

        if ($(this).hasClass('active')) {
            $(this).removeClass('active').find('.answer').slideUp();
        } else {
            $(this).addClass('active').find('.answer').slideDown();
            $(this).siblings().removeClass('active').find('.answer').slideUp();
        }
        
        controller = controller.destroy(true);
        animations();
    });
};

var mediaSlider = function () {
    $('.slider-container-preview').slick({
        slidesToShow: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-thumbs-list'
    });

    $('.slider-thumbs-list').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.slider-container-preview',
        dots: false,
        arrows: true,
        centerMode: false,
        focusOnSelect: true,
        prevArrow: $('.slider-container-nav-left'),
        nextArrow: $('.slider-container-nav-right'),
        pauseOnHover: true,
        variableWidth: false,
        adaptiveHeight: true,
        responsive: [{
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    });
};


$('#close-m-message-popup').on('click', function () {
    $('#m-message-popup').fadeOut(300);

    sessionStorage.setItem('info-popup', true);
});

$('#open-popup').on('click', function () {
    if (sessionStorage.getItem('info-popup')) {
        sessionStorage.removeItem('info-popup');

        $('#m-message-popup').fadeIn(300);
    }
});

$(window).on('load', function () {
    setTimeout(() => {
        //Initialise sidebar scroll
        animations();
    }, 650);
});

if(navigator.userAgent.indexOf('Mozilla') || navigator.userAgent.indexOf('Firefox') != -1) {
    window.onunload = function(){};
}

$('.close-message--icon').on('click', function(e) {
    e.preventDefault();

    if('registerSuccess' in sessionStorage) {
        sessionStorage.removeItem('registerSuccess');        
        setTimeout(() => window.location.reload(), 500);
        // var session = sessionStorage.getItem('registerSuccess');
        // var result = $.parseJSON(session);

        // if(result === true) {
            // sessionStorage.removeItem('registerSuccess');
            // $('.alert-wrapper-success').remove();
        // }

    }
})

$(function () {

    if(sessionStorage.getItem('editable')) sessionStorage.removeItem('editable');

    if (sessionStorage.getItem('info-popup')) {
        $('#m-message-popup').hide();
    }

    // if('registerSuccess' in sessionStorage) {
    //     var session = sessionStorage.getItem('registerSuccess');
    //     var result = $.parseJSON(session);

    //     if(result === true) {
    //         setTimeout(() => {
    //             sessionStorage.removeItem('registerSuccess');
    //             $('.alert-wrapper-success').remove();
    //         }, 3000);
    //     } else {
    //         sessionStorage.removeItem('registerSuccess');
    //         $('.alert-wrapper-success').remove();   
    //     }
    // } else {
    //     $('.alert-wrapper-success').remove();
    // }

    if('savedForm' in sessionStorage) {
        var session = sessionStorage.getItem('savedForm');
        var result = $.parseJSON(session)

        if(result === true) {
            clearForm();
            formSavedSuccess('Formularz został poprawnie zapisany');

            setTimeout(() => {            
                sessionStorage.removeItem('savedForm');
                $('.form-loaded-success').remove();
            }, 3000);
        }
    } else {
        clearForm();
    }

    if ($('#citizenship').length > 0 && $('#country-document').length > 0) {
        $('#citizenship option, #country-document option').each((i, e) => {
            if (e.value === 'XA' || e.value === 'XB') e.remove();
        });
    }

    $('#m-details-form').hide();

    $('select, input, textarea').addClass('form-field');

    fullheight();
    validator();
    validateMillenniumCard();
    validateDocumentType();
    validatePhoneNumberLength();
    numberCorrection();
    // cookies();
    textOrphans();
    faq();
    mediaSlider();

    if($('.app-card[data-card="millennium"]').length > 0) {
        verifyMillenniumCard();
    }
});